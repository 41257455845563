export class SimpleLookupModel {
  id: number;
  text: string;
  code: string;
}

export class SimpleTextLookupModel {
  value: string;
  text: string;
}

export class GroupedLookupsModel {
  groupId: number;
  groupName: string;
  lookups: SimpleLookupModel[];
}